import { useMarketsBookStore } from 'markets-store/marketsbook/store/marketsBookStore'
import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TSportEvent } from 'markets-store/types'
import {
  MARKET_ALLOWED_STATES,
  SPORT_EVENT_STATUSES_ENUM,
} from 'markets-store/constants'
import { addNamesToMarket } from 'markets-store/helpers/addNamesToMarket'
import { groupBy } from '@st/utils'
import { getMarketsFromConfigLine } from '../../helpers/getMarketsFromConfigLine'

export function useMainMarket(
  event: Ref<TSportEvent>,
  filterConfig?: Ref<FilterConfig | undefined>,
) {
  const MS = useMarketsStore()
  const marketsBookStore = useMarketsBookStore()
  const { marketsFiltersConfig } = storeToRefs(marketsBookStore)

  const eventMarkets = useMarketsByEventId(() => event.value.id)
  const activeMarkets = computed(() =>
    eventMarkets.value.filter(
      (market) =>
        MARKET_ALLOWED_STATES.includes(market.status) &&
        market.outcomes?.length,
    ),
  )
  const marketsGroupedById = computed(() =>
    groupBy(activeMarkets.value, (item) => item.id),
  )
  const eventState = computed<'live' | 'prematch'>(() =>
    event.value.status === SPORT_EVENT_STATUSES_ENUM.live ? 'live' : 'prematch',
  )

  function checkMarketFromConfigAvailable(config: FilterConfig) {
    // общий массив id маркетов, оснонвые и на периоды
    const marketsIds = Object.values(config.markets).flat()

    const isSomeMarketAvailable = marketsIds.some(
      (id) => marketsGroupedById.value.get(id).length,
    )
    return isSomeMarketAvailable ? config : null
  }

  const mainMarketConfig = computed(() => {
    if (filterConfig?.value) {
      return checkMarketFromConfigAvailable(filterConfig.value)
    }

    if (!marketsFiltersConfig.value.default) return null

    const marketsFiltersByState =
      (marketsFiltersConfig.value[event.value.sportId] ||
        marketsFiltersConfig.value.default)[eventState.value] ?? []

    return (
      marketsFiltersByState.find((item) =>
        checkMarketFromConfigAvailable(item),
      ) ?? null
    )
  })

  const mainMarket = computed(() => {
    const marketsFromConfig = getMarketsFromConfigLine({
      marketsFilter: mainMarketConfig.value,
      marketList: activeMarkets.value,
      line: 'all',
    })

    return marketsFromConfig
  })

  const marketName = computed(() => {
    const firstMarket = Array.isArray(mainMarket.value)
      ? mainMarket.value[0]
      : mainMarket.value

    if (firstMarket && MS.systemStates.sbCacheInited) {
      addNamesToMarket({
        marketsStore: MS,
        market: firstMarket,
      })
    }
    return firstMarket?.name ?? ''
  })
  const eventId = computed(() => event.value.id)
  if (!activeMarkets.value.length) {
    useDownloadAllMarkets(eventId)
  }

  return {
    activeMarkets,
    marketsGroupedById,
    mainMarketConfig,
    marketName,
    mainMarket,
  }
}
